<template>
  <div>
    <transition appear name="slide">
      <side-bar
        v-if="isAuthenticated"
        @toggleSearch="$emit('toggleSearch')"
      ></side-bar>
      <side-bar-no-auth v-if="!isAuthenticated"></side-bar-no-auth>
    </transition>
  </div>
</template>

<script>
import SideBar from '@/components/Layout/SideBar.vue';
import SideBarNoAuth from '@/components/Layout/SideBarNoAuth.vue';
import { subscribe } from '@/api/Cherry';

export default {
  name: 'NavigationBar',
  components: {
    SideBar,
    SideBarNoAuth,
  },
  mounted() {
    this.checkTrialStatus();
  },
  methods: {
    async checkTrialStatus() {
      let plan = this.$store.getters['plan'];
      let era = this.$store.getters['era'];
      let trialConsumed = this.$store.getters['trialConsumed'];
      let errorMsg = 'readonly-trialended';
      let message = '';
      if (plan == 0 && era == 1 && trialConsumed) {
        await subscribe().then((r) => {
          if (r.URL) {
            message = [
              {
                link: {
                  text: 'Reactivate your Meetric account',
                  isExternal: true,
                  to: r.URL,
                },
              },
            ];
            this.$notification(message, {
              closable: false,
              type: 'warning',
              errorMsg,
            });
          } else {
            console.error('Problem finding payment URL');
          }
        });
      }
    },
  },
  computed: {
    isAuthenticated() {
      return this.$gAuth.isAuthorized;
    },
  },
};
</script>

<style scoped>
.slide-enter-active {
  transition: transform 0.8s ease;
}
.slide-enter {
  @apply transform -translate-y-full translate-x-0 lg:-translate-x-full lg:translate-y-0;
}
</style>
