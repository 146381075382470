<template>
  <div class="lg:mx-0 lg:mt-8">
    <a
      v-if="dest == 'helpnotion'"
      href="https://meetric.notion.site/Meetric-Help-Center-4c5d2585197343b1910329c24b6da9fc"
      target="_blank"
      segmentName="Get help"
      :class="!dest ? 'cursor-default' : ''"
    >
      <div @click="sidebarClick">
        <div
          v-if="isActive"
          class="
            invisible
            lg:visible
            absolute
            inset-x-0
            h-8
            w-1
            bg-white
            rounded-r
            -mt-1
          "
        ></div>
        <div
          style="width: 1.4rem; height: 1.4rem"
          :data-tooltip="'baricon-' + _uid"
        >
          <slot></slot>
        </div></div
    ></a>
    <router-link
      v-else
      :to="dest && dest != 'search' ? { name: dest } : {}"
      :class="!dest ? 'cursor-default' : ''"
    >
      <div @click="sidebarClick">
        <div
          v-if="isActive"
          class="
            invisible
            lg:visible
            absolute
            inset-x-0
            h-8
            w-1
            bg-white
            rounded-r
            -mt-1
          "
        ></div>
        <div
          style="width: 1.4rem; height: 1.4rem"
          :data-tooltip="'baricon-' + _uid"
        >
          <slot></slot>
        </div>
      </div>
    </router-link>
    <hover-tool-tip
      :url="supportUrl"
      :label="label"
      :selector="`[data-tooltip=&quot;baricon-${_uid}&quot;]`"
      :placement="isExtension ? 'bottom' : 'right'"
      :offset="isExtension ? 15 : 20"
    ></hover-tool-tip>
  </div>
</template>

<script>
import HoverToolTip from '@/components/UI/HoverToolTip';

export default {
  name: 'SidebarIcon',
  components: {
    HoverToolTip,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    dest: {
      type: String,
      required: true,
    },
    supportUrl: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showTooltip: false,
      currentRouteName: '',
      id: null,
    };
  },
  mounted() {
    this.currentRouteName = this.$route.name;
  },
  watch: {
    '$route.name'(to) {
      this.currentRouteName = to;
    },
  },
  methods: {
    sidebarClick() {
      this.$emit('click');

      window.meetric.track(this.dest, {
        icon: this.icon,
        type: 'Navbar click',
      });
    },
  },
  computed: {
    isExtension() {
      return !!this.$store?.getters['isExtension'];
    },
    isActive() {
      return (
        (this.currentRouteName === this.dest && this.dest != '') ||
        (this.currentRouteName === 'meeting' && this.dest === 'now')
      );
    },
  },
};
</script>

<style scoped>
.meetric-logo-long {
  @apply -mt-1;
  width: 6rem;
  height: auto;
}
.meetric-logo {
  width: 1.25rem;
  height: auto;
}
</style>
